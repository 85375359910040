import React, { useEffect, useState } from "react";
import { Button } from '@mui/material';
import JobAdsListTable from "./components/JobAdsTable";
import JobAdsDetails from "./components/JobAdsDetails";
import SelectBox from "./components/Shared/SelectBox";
import { APP_VIEWS,DESCRIPTION_TEMPLATE } from "./constants";
import API from "./services/API";
import { RECRUITMENT_ADS, SERVICES, INQUIRY_ADS } from "./services/API/endpoints";

export default function App(props) {
  const globalConfig = typeof jobAdsPluginSettings !== "undefined" ? jobAdsPluginSettings : {};
  const [inquiryList, setInquiryList] = useState([]);
  const [jobAdsList, setJobAdsList] = useState([]);
  const [jobAdsListAll, setJobAdsListAll] = useState([]);
  const [loadingJobAds, setLoadingJobAds] = useState(true);
  const [jobAdsUsedServicesIDs, setJobAdsUsedServicesIDs] = useState([]);
  const [servicesListFromAPI, setServicesListFromAPI] = useState([]);
  const [professionListFromAPI, setProfessionListFromAPI] = useState([]);
  const [servicesListForDropdown, setServicesListForDropdown] = useState([]);
  const [professionListForDropdown, setProfessionListForDropdown] = useState([]);
  const [locationListForDropdown, setLocationListForDropdown] = useState([]);
  const [groupedLocations, setGroupedLocations] = useState([]);
  const [activeView, setActiveView] = useState(APP_VIEWS.JOB_ADS_LIST);
  const [selectedJobAd, setSelectedJobAd] = useState({});
  const [selectedService, setSelectedService] = useState(0);
  const [selectedProfession, setSelectedProfession] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [jobAdsDataFilter, setJobAdsDataFilter] = useState({service:0, profession:0 ,location:0});
  const [inquiryListLoaded, setInquiryListLoaded] = useState(false);
  const [jobAdsLoaded, setJobAdsLoaded] = useState(false);

  let allProfessions = [];

  const customConfigStyling = {
    getPrimaryButtonStyling: () => {
      let styling = {};
      if (globalConfig?.primaryButtonBackgroundColor)
        styling.backgroundColor = globalConfig?.primaryButtonBackgroundColor;
      if (globalConfig?.primaryButtonTextColor) 
        styling.color = globalConfig?.primaryButtonTextColor;

      return styling;
    },
    getSecondaryButtonStyling: () => {
      let styling = {};
      if (globalConfig?.secondaryButtonBackgroundColor)
        styling.backgroundColor = globalConfig?.secondaryButtonBackgroundColor;
      if (globalConfig?.secondaryButtonTextColor) 
        styling.color = globalConfig?.secondaryButtonTextColor;

      return styling;
    },
    getLinkElementStyling: () => {
      let styling = {};
      if (globalConfig?.linkElementTextColor) 
        styling.color = globalConfig?.linkElementTextColor;

      return styling;
    },
  };

  const handleJobAdDetailsURLParams = (shouldReset, adid) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete("adid");
    var newurl = document.location.origin;

    if (!shouldReset) urlSearchParams.set("adid", adid);

    newurl += document.location.pathname + "?" + urlSearchParams.toLocaleString();
    window.history.pushState(newurl, "", newurl);
  };

  const handleFilterJobAdsURLParams = (shouldReset, filterType, paramValue) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete(filterType);
    var newurl = document.location.origin;

    urlSearchParams.delete('serviceName');
    urlSearchParams.delete('professionName');
    urlSearchParams.delete('locationName');

    if (!shouldReset) 
      urlSearchParams.set(filterType, paramValue);
    else{
      urlSearchParams.delete('service');
      urlSearchParams.delete('profession');
      urlSearchParams.delete('location');
    }

    newurl += document.location.pathname + "?" + urlSearchParams.toLocaleString();
    window.history.pushState(newurl, "", newurl);
  }

  const handleUserNavigation = (targetView, data) => {
    if (targetView === APP_VIEWS.JOB_ADS_LIST) {
      handleJobAdDetailsURLParams(true);
    } else if (targetView === APP_VIEWS.JOB_DETAILS) {
      handleJobAdDetailsURLParams(false, data);
    }
    else if (targetView === APP_VIEWS.DEMO_FORM){
      handleJobAdDetailsURLParams(true);
    }
    setActiveView(targetView);
  };

  const handleJobAdSelection = (selectedItem) => {
    if(selectedItem && selectedItem.id){
      let selectedJobFullData = jobAdsList.find((item) => item.id === selectedItem.id);
      setSelectedJobAd(selectedJobFullData);
    }
  };
  const getJobAdByID = (id) => {
    return jobAdsList.find((item) => item.id === id);
  };

  const filterJobAdsTable = () => {
    let filteredData = [];
    if(!jobAdsDataFilter.service && !jobAdsDataFilter.profession && !jobAdsDataFilter.location){
      setJobAdsList(jobAdsListAll);
      return;
    }
    
    if(jobAdsDataFilter.service > 0){
      let selectedParentService = getServiceByID(jobAdsDataFilter.service)
      let allChildItemsFromService = []
      if(selectedParentService){
        allChildItemsFromService = getAllProfessionsForService(selectedParentService, true)        
      }
      jobAdsListAll.forEach(item => {
        if(item.service.id === jobAdsDataFilter.service || allChildItemsFromService.includes(item.service.id))
          filteredData.push(item)
      })      
      setTimeout(function () {
        handleDropdownChange('service', jobAdsDataFilter.service)
      },0)
    }

    if(jobAdsDataFilter.service > 0 && jobAdsDataFilter.profession > 0){
      filteredData = filteredData.filter(item => item.service.id == jobAdsDataFilter.profession);
      setTimeout(function () {
        handleDropdownChange('profession', jobAdsDataFilter.profession)
      },0)
    }
    else if(jobAdsDataFilter.profession > 0){
      filteredData = jobAdsListAll.filter(item => item.service.id == jobAdsDataFilter.profession);
      setTimeout(function () {
        handleDropdownChange('profession', jobAdsDataFilter.profession)
      },0)
    }
    if(!jobAdsDataFilter.service && !jobAdsDataFilter.profession){
      filteredData = [...jobAdsListAll]
    }

    if(jobAdsDataFilter.location > 0){
      setTimeout(function () {
        handleDropdownChange('location', jobAdsDataFilter.location)
      },0)
      let allGroupedCounties = [...groupedLocations];
      if(!allGroupedCounties.length)
        allGroupedCounties = handleGroupedLocations()

      let selectedCounty = allGroupedCounties.find(item => item.id == jobAdsDataFilter.location)
      if(selectedCounty){
        filteredData = filteredData.filter(item => selectedCounty.locationIDs.includes(item.municipality.id));
      }  
    }

    setJobAdsList(filteredData);
  }

  const convertAndAddToJobAdsList = (data) => {
    let inquiryJobAds = []

    if(data && data.length){
      data.map((item)=>{
        if(item && item.ad && item.ad.enabled){
          
          let jobTitle = item.ad.header;
          let translatedTo = globalConfig?.translations?.to ? globalConfig?.translations?.to : ' for '
          if(!jobTitle && item.services && item.services.length && item.customer && item.customer.name){
            jobTitle = item.services[0].name + translatedTo + item.customer.name;

            if(!item.ad.description){
              let defaultDescription = DESCRIPTION_TEMPLATE;
              defaultDescription = defaultDescription.replaceAll('__SERVICE__',item.services[0].name)
              defaultDescription = defaultDescription.replaceAll('__CUSTOMER__',item.customer.name)
              item.ad.description = defaultDescription
            }
          }

          inquiryJobAds.push({
            _type: 'Inquiry',
            applyUrl : null,
            contacts : [],
            createdAt : item.ad.publishedAt,
            description: item.ad.description,
            employmentDescription: item.availableWeeksString,
            id : item.id,
            municipality : {id: parseInt(Math.random()*100000), name: item.customer?.address?.city, county: item.customer?.address?.county},
            publishedAt : item.ad.publishedAt,
            service :  (item.services && item.services.length)? item.services[0] : null,
            title : jobTitle,
            url : null,
            user :  {id: item.ad.user?.id, name: item.ad.user?.name, phone: item.ad.user?.phone, email: item.ad.user?.phone}
          })
        }
      })
    }
    let existingJobAds = [...jobAdsListAll]
    setJobAdsListAll(existingJobAds.concat(inquiryJobAds))
  }
  

  const setDataBasedOnEndpoint = (endpoint, data) => {
    switch (endpoint) {
      case RECRUITMENT_ADS:
        setLoadingJobAds(false);
        setJobAdsListAll(data);
        setJobAdsList(data);
        setJobAdsLoaded(true)
        break;
      case SERVICES:
        setServicesListFromAPI(data);
        break;
      case INQUIRY_ADS:
        setInquiryList(data)
        setInquiryListLoaded(true)
        break;
      default:
        break;
    }
  };

  const getAllDataFromAPICall = (endpoint) => {
    API.get(endpoint).then(function (result) {
      // check if response is with pagination.
      if (result.pageCount > 1) {
        let allApiCalls = [];
        for (var page = 1; page <= result.pageCount; page++) {
          if(endpoint == INQUIRY_ADS || endpoint == RECRUITMENT_ADS)
            allApiCalls.push(API.get(endpoint + "&page=" + page));
          else
            allApiCalls.push(API.get(endpoint + "/?page=" + page));
        }

        Promise.all(allApiCalls)
          .then(function (responses) {
            // Get a JSON object from each of the responses
            return Promise.all(
              responses.map(function (response) {
                return response;
              })
            );
          })
          .then(function (data) {
            //Loop through each of the responses and combine them into one array
            var allItems = [];
            data.forEach(function (dataBatch) {
              allItems = allItems.concat(dataBatch.items);
            });
            setDataBasedOnEndpoint(endpoint, allItems);
          })
          .catch(function (error) {
            setDataBasedOnEndpoint(endpoint, []);
          });
      } else if (result.items) {
        setDataBasedOnEndpoint(endpoint, result.items);
      } else setDataBasedOnEndpoint(endpoint, []);
    });
  };

  const prepareProfessionsFromServices = (service) => { 
    if(service instanceof Array) {
        for(var i = 0; i < service.length; i++) {
          prepareProfessionsFromServices(service[i]);
        }
    }
    else
    {
        for(var prop in service) {
            if(prop === 'children') {
                if(service[prop].length) {
                   allProfessions = allProfessions.concat(service[prop]);
                }
            }
            if(service[prop] instanceof Object || service[prop] instanceof Array){
              prepareProfessionsFromServices(service[prop]);}
        }
    }
  }
 
  const filterDropdownsBasedOnJobAds = () => {
    let filteredServicesList = []
    let filteredProfessionList = []

    if(jobAdsList.length){
      jobAdsList.forEach((item)=>{
        let parentService = getServiceGroupByServiceID(item.service)
        if(!filteredServicesList.includes(parentService)){
          filteredServicesList.push(parentService)
        }
      })
    }

    if(professionListFromAPI.length){
      professionListFromAPI.forEach((item)=>{
        if(jobAdsUsedServicesIDs.includes(item.id))
          filteredProfessionList.push(item)
      })
    }
    setServicesListForDropdown(filteredServicesList)
    setProfessionListForDropdown(filteredProfessionList)
  }

  const applyAllURLParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const selectedJobAdId = urlSearchParams.get("adid");
    if (selectedJobAdId) {
      handleJobAdSelection(getJobAdByID(selectedJobAdId));
      handleUserNavigation(APP_VIEWS.JOB_DETAILS, selectedJobAdId);
    }
    else{
      let selectedServiceID = urlSearchParams.get("service");
      let serviceNameParam = urlSearchParams.get("serviceName");
      let selectedProfessionID = urlSearchParams.get("profession");
      let professionNameParam = urlSearchParams.get("professionName");
      let selectedLocationID = urlSearchParams.get("location");
      let locationNameParam = urlSearchParams.get("locationName");

      if(selectedServiceID)
        setSelectedService(selectedServiceID)
      if(selectedProfessionID)
        setSelectedProfession(selectedProfessionID)
      if(selectedLocationID)
        setSelectedLocation(selectedLocationID)

      let selectedService = null;
      if(serviceNameParam){
        serviceNameParam = decodeURIComponent((serviceNameParam).replace(/\+/g, '%20'));
        selectedService = getServiceByName(serviceNameParam)
        if(selectedService.id)
          setSelectedService(selectedService.id)
      }   
      
      let selectedProfession = null;
      if(professionNameParam){
        professionNameParam = decodeURIComponent((professionNameParam).replace(/\+/g, '%20'));
        selectedProfession = getProfessionByName(professionNameParam)
        if(selectedProfession.id)
          setSelectedProfession(selectedProfession.id)
      } 

      let selectedLocation = null;
      if(locationNameParam){
        locationNameParam = decodeURIComponent((locationNameParam).replace(/\+/g, '%20'));
        selectedLocation = getLocationByName(locationNameParam)
        if(selectedLocation.id)
          setSelectedLocation(selectedLocation.id)
      } 
    }

    filterJobAdsTable()
  }

  const getServiceByID = (id) => {
    let selectedService = {}
    servicesListFromAPI.forEach(item => {
      if(item.id === id) selectedService = item
    })
    return selectedService
  }

  const getServiceByName = (name) => {
    let selectedService = {}
    servicesListFromAPI.forEach(item => {
      if(item.name.toLowerCase() == name.toLowerCase()) selectedService = item
    })
    return selectedService
  }
  const getProfessionByName = (name) => {
    let selectedProfession = {}
    professionListFromAPI.forEach(item => {
      if(item.name.toLowerCase() == name.toLowerCase()) selectedProfession = item
    })
    return selectedProfession
  }
  const getLocationByID = (id) => {
    let selectedLocation = {}
    let allLocations = handleGroupedLocations(true)
    allLocations.forEach(item => {
      if(item.id == id) selectedLocation = item
    })
    return selectedLocation
  }

  const getLocationByName = (name) => {
    let selectedLocation = {}
    let allLocations = handleGroupedLocations(true)
    allLocations.forEach(item => {
      if(item.name.toLowerCase() == name.toLowerCase()) selectedLocation = item
    })
    return selectedLocation
  }

  const getParentServiceNameByServiceID = (service) => {
    let parentService = getServiceByID(service.id);
    if(parentService && parentService.name){
      return parentService.name;
    }
    professionListFromAPI.map((item)=>{
      if(item.id == service.id){
        let parentServiceName = item.path.split('>')[0].trim()
        parentService = getServiceByName(parentServiceName)
      }
    })

    if(parentService && parentService.name){
      return parentService.name;
    }

    return ''
  }

  const getServiceGroupByServiceID = (service) => {
    let parentService = getServiceByID(service.id);
    if(parentService && parentService.name){
      return parentService;
    }
    professionListFromAPI.map((item)=>{
      if(item.id == service.id){
        let parentServiceName = item.path.split('>')[0].trim()
        parentService = getServiceByName(parentServiceName)
      }
    })

    if(parentService && parentService.name){
      return parentService;
    }

    return ''
  }

  const getAllProfessionsForService = (service, onlyIDs) => {
    let allChildItemsFromService = []
    let allUsedServicesIDs = [...jobAdsUsedServicesIDs]
    if(!allUsedServicesIDs.length){
      jobAdsList.forEach((item) => {
        if (item.service && item.service.id && !allUsedServicesIDs.includes(item.service.id))
        allUsedServicesIDs.push(item.service.id);       
      });
    }
    professionListFromAPI.forEach(item=>{
      if(item.path.split('>')[0].indexOf(service.name)!==-1){
        if(allUsedServicesIDs.includes(item.id))
          if(onlyIDs)
            allChildItemsFromService.push(item.id)
          else
            allChildItemsFromService.push(item)
      }
    })
    return allChildItemsFromService;
  }

  const handleDropdownChange = (type, value) => {
    switch(type){
      case 'service':
        setSelectedService(value)
        setSelectedProfession(0)
        setSelectedLocation(0)
        if(value){
          let selectedParentService = getServiceByID(value)
          let allChildItemsFromService = []
          if(selectedParentService)
            allChildItemsFromService = getAllProfessionsForService(selectedParentService)
          setProfessionListForDropdown(allChildItemsFromService)
        }
        else{
          filterDropdownsBasedOnJobAds();
        }
        break;
      case 'profession':
        setSelectedProfession(value);
        break;
      case 'location':
        setSelectedLocation(value);
        break;
      default:
        break;
    }
    handleFilterJobAdsURLParams(false, type, value)
  }

  const clearFilters = () => {
    handleFilterJobAdsURLParams(true)
    setSelectedService(0)
    setSelectedProfession(0)
    setSelectedLocation(0)
    filterDropdownsBasedOnJobAds()
    setJobAdsList(jobAdsListAll)
  }

  useEffect(() => {
    setLoadingJobAds(true)
    getAllDataFromAPICall(RECRUITMENT_ADS);
    getAllDataFromAPICall(SERVICES);
    getAllDataFromAPICall(INQUIRY_ADS);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(jobAdsLoaded && inquiryListLoaded)
      convertAndAddToJobAdsList(inquiryList)
    // eslint-disable-next-line
  }, [jobAdsLoaded,inquiryListLoaded]);

  

  useEffect(() => {
    if(jobAdsUsedServicesIDs.length){
      filterDropdownsBasedOnJobAds()
    }
    // eslint-disable-next-line
  }, [jobAdsUsedServicesIDs]);

  useEffect(() => {
    if (servicesListFromAPI) {
      prepareProfessionsFromServices(servicesListFromAPI)
      if(allProfessions.length)
        setProfessionListFromAPI(allProfessions)
    }
    // eslint-disable-next-line
  }, [servicesListFromAPI]);

  useEffect(() => {
    if (professionListFromAPI) {
      setJobAdsList([])
      setTimeout(function () {
        filterDropdownsBasedOnJobAds();
        filterJobAdsTable();
      },0)
    }
    // eslint-disable-next-line
  }, [professionListFromAPI]);

  

  const handleGroupedLocations = (getOnly) => {
    let locationItems = [];
    let countyListWithChildItemIDs = {}

    jobAdsListAll.forEach((item) => {
      if (item.municipality && item.municipality.county && !locationItems.some(e => e.id === item.municipality.id)) {
        locationItems.push(item.municipality)
        if(!countyListWithChildItemIDs[item.municipality.county])
          countyListWithChildItemIDs[item.municipality.county] = { name: item.municipality.county, children: [item.municipality.id] }
        else
          countyListWithChildItemIDs[item.municipality.county].children.push(item.municipality.id)
      }        
    });
    locationItems = Object.keys(countyListWithChildItemIDs).map((key,index) => {
      return {
        id: index+1,
        name: countyListWithChildItemIDs[key].name,
        locationIDs: countyListWithChildItemIDs[key].children
      }
    });

    if(!getOnly){
      setLocationListForDropdown(locationItems)
      setGroupedLocations(locationItems)
    }

    return locationItems
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    window.addEventListener("popstate", function (e) {
      var state = e.state;
      if (state !== null) {
        handleUserNavigation(APP_VIEWS.JOB_ADS_LIST);
      }
    });

    let jobAdsServicesIDs = [];
    jobAdsListAll.forEach((item) => {
      if (item.service && item.service.id && !jobAdsServicesIDs.includes(item.service.id)){
        jobAdsServicesIDs.push(item.service.id);
      }
    });

    handleGroupedLocations()

    setJobAdsUsedServicesIDs(jobAdsServicesIDs)

    setTimeout(function(){
      applyAllURLParams()
    },0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobAdsListAll]);

  useEffect(() => {
    filterJobAdsTable()
    // eslint-disable-next-line
  }, [jobAdsDataFilter]);

  useEffect(() => {
    setJobAdsDataFilter({
      service: selectedService,
      profession: selectedProfession,
      location: selectedLocation,
    })
  }, [selectedService, selectedProfession, selectedLocation]);

  return (
    <div className={"JobAdsApplication " + (globalConfig?.cssPluginWrapperClass ? globalConfig.cssPluginWrapperClass : "")}>
      {activeView === APP_VIEWS.JOB_ADS_LIST && (
        <div className="jobAdsListWrapper">
          {!globalConfig?.hideFilters &&
            <div className="dropdownControlsWrapper">
              <SelectBox
                id="serviceDropdown"
                type="service"
                label={globalConfig?.translations?.ServiceGroup ? globalConfig?.translations?.ServiceGroup : 'Service Group'}
                options={servicesListForDropdown}
                className="dropdown serviceDropdown"
                handleDropdownChange={handleDropdownChange}
                value={selectedService}
                globalConfig={globalConfig}
              />
              <SelectBox
                id="professionDropdown"
                type="profession"
                label={globalConfig?.translations?.Profession ? globalConfig?.translations?.Profession : 'Profession'}
                options={professionListForDropdown}
                className="dropdown professionDropdown"
                handleDropdownChange={handleDropdownChange}
                value={selectedProfession}
                globalConfig={globalConfig}
              />
              <SelectBox
                id="locationDropdown"
                type="location"
                label={globalConfig?.translations?.Location ? globalConfig?.translations?.Location : 'Location'}
                options={locationListForDropdown}
                className="dropdown locationDropdown"
                handleDropdownChange={handleDropdownChange}
                value={selectedLocation}
                globalConfig={globalConfig}
              />
              <Button 
                variant="outlined"
                className="btnClearFilters"
                onClick={clearFilters}
              >{globalConfig?.translations?.Clear ? globalConfig?.translations?.Clear : 'Clear'}</Button>
            </div>
          }
          <JobAdsListTable
            jobAdsList={jobAdsList}
            getParentServiceNameByServiceID={getParentServiceNameByServiceID}
            globalConfig={globalConfig}
            handleUserNavigation={handleUserNavigation}
            handleJobAdSelection={handleJobAdSelection}
            loadingJobAds={loadingJobAds}
          />
        </div>
      )}
      {activeView === APP_VIEWS.JOB_DETAILS && (
        <JobAdsDetails
          selectedJobAd={selectedJobAd}
          handleUserNavigation={handleUserNavigation}
          getJobAdByID={getJobAdByID}
          globalConfig={globalConfig}
          customConfigStyling={customConfigStyling}
        />
      )}
    </div>
  );
}
