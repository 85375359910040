import React, { useState, useEffect } from "react"
import {
  TextField,
} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

export default function Questionnaire(props) {
  const [questionnaireList, setQuestionnaireList] = useState(props.questionnaireList)
  const startTabIndex = props.startTabIndex ? props.startTabIndex : 0;

  useEffect(() => {
    if (!props.questionnaireList) return;

    let preparedData = props.questionnaireList
    preparedData.forEach((item) => {
      if (item && item.options && item.options.choices) {
        item.options.choices.forEach((qcItem, qcIndex) => {
          qcItem.index = qcIndex
          if (!qcIndex && item.type == "choice")
            qcItem.checked = true
          else
            qcItem.checked = false
        })
      }
    })

    setQuestionnaireList(preparedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionnaireList])

  const updatedQuestionListData = (question, answer, choiceIndex, newValue) => {
    let questionnaireListUpdated = [...questionnaireList];

    if(questionnaireListUpdated){
      questionnaireListUpdated.forEach(function (question) {
        if(question.type == 'multichoice' && question.options && question.options.choices && question.options.choices[choiceIndex]){
          question.options.choices[choiceIndex].checked = newValue
        }
      })
    }

    setQuestionnaireList(questionnaireListUpdated)
  }

  return (
    <div className="fullRow questionnaireListWrapper mt-3">
      {questionnaireList && questionnaireList.length>0 &&
        <div className="questionnaireListBox">
          {questionnaireList.map((questionItem, index) => {
            switch (questionItem.type) {
              case 'input':
                return (
                  <div key={index} className="fullrow inputWrapper mobile-mb-25">
                    <TextField
                      className="stdInputFiled textAreaField"
                      label={questionItem.title}
                      multiline
                      rows={1}
                      inputProps={{
                        tabIndex: parseInt(startTabIndex + index),
                      }}
                      onChange={(event) => {
                        props.handleQuestionnaireChange(questionItem, event.target.value)
                      }}
                    />
                  </div>
                )
              case 'choice':
                return (
                  <div key={index} className="fullrow inputWrapper mobile-mb-25">
                    <FormControl>
                      <FormLabel>{questionItem.title}</FormLabel>
                      <RadioGroup row>
                        {questionItem.options.choices.map((answerItem, qcIndex) => {
                          return (
                            <FormControlLabel
                              label={answerItem.choice}
                              name={'qc_name_' + answerItem.choice}
                              key={'qc_' + qcIndex}
                              className="radioBtnWrapper"
                              onClick={(e) => {
                                props.handleQuestionnaireChange(questionItem, answerItem)
                              }}
                              value={answerItem.checked}
                              control={<Radio className="radioBtn" />}
                            />
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </div>
                )
              case 'multichoice':
                return (
                  <div key={index} className="fullrow inputWrapper mobile-mb-25">
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">{questionItem.title}</FormLabel>
                      <FormGroup row>
                        {questionItem.options.choices.map((answerItem, qcIndex) => {
                          return (
                            <FormControlLabel
                              label={answerItem.choice}
                              name={'qmc_name_' + answerItem.choice}
                              key={'qmc_' + qcIndex}
                              className="chboxWrapper"
                              control={<Checkbox 
                                checked={answerItem.checked} 
                                className="chBtn" 
                                onClick={(e) => {
                                  updatedQuestionListData(questionItem, answerItem, qcIndex, e.target.checked)
                                  props.handleQuestionnaireChange(questionItem, answerItem)
                                }}
                              />}
                            />
                          )
                        })}
                      </FormGroup>
                    </FormControl>
                  </div>
                )
            }

          })}
        </div>
      }
    </div>
  )
}
