export const APP_VIEWS = {
	JOB_ADS_LIST: "joblist",
	JOB_DETAILS: "adid"
}
export const DESCRIPTION_TEMPLATE = "Nu söker vi en engagerad och professionell konsult inom __SERVICE__ till uppdrag för oss hos vår kund __CUSTOMER__. Dina arbetsuppgifter består av sedvanliga arbetsuppgifter som konsult inom __SERVICE__. Det är meriterande om du har erfarenhet inom denna typ av uppdrag och bemanning.";

export const JOB_IMAGES_LIST = [
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax1.jpg',
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax2.jpg',
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax3.jpg',
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax4.jpg',
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax5.jpg',
    'https://jobadsplugin.s3.amazonaws.com/plugin-ads-images/bemannax6.jpg'
]