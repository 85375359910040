import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    IconButton,
    TextField,
    InputAdornment,
    Alert,
    AlertTitle
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import HomeIcon from '@mui/icons-material/Home';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ReCAPTCHA from "react-google-recaptcha";
import Questionnaire from './Questionnaire'
import API from "../../services/API";
import { RECRUITMENT_ADS_WITH_ID, RECRUITMENT_ADS_WITH_ID_APPS, INQUIRY_ADS_WITH_ID_APPS } from "../../services/API/endpoints";
import { APP_VIEWS } from "../../constants"

import "./style.css";

const uploadedFileSizeLimit = 10485760;
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function ApplyForJobForm(props) {
    const [jobAdDetails, setJobAdDetails] = useState();
    const [questionnaireId, setQuestionnaireId] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [firstNameValidationFailed, setFirstNameValidationFailed] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameValidationFailed, setLastNameValidationFailed] = useState(false);
    const [email, setEmail] = useState('');
    const [emailValidationFailed, setEmailValidationFailed] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [birthDateFormated, setBirthDateFormated] = useState('');
    const [birthDateValidationFailed, setBirthDateValidationFailed] = useState(false);
    const [birthDateErrorMsg, setBirthDateErrorMsg] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [mobilePhoneValidationFailed, setMobilePhoneValidationFailed] = useState(false);
    const [homePhone, setHomePhone] = useState('');
    const [homePhoneValidationFailed, setHomePhoneValidationFailed] = useState(false);
    const [address, setAddress] = useState('');
    const [addressValidationFailed, setAddressValidationFailed] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [postalCodeValidationFailed, setPostalCodeValidationFailed] = useState(false);
    const [city, setCity] = useState('');
    const [cityValidationFailed, setCityValidationFailed] = useState(false);
    const [personalLetter, setPersonalLetter] = useState('');
    const [personalLetterValidationFailed, setPersonalLetterValidationFailed] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState({ type: '', message: '' })
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFileBase64, setUploadedFileBase64] = useState('');
    const [uploadedFileFailed, setUploadedFileFailed] = useState(false);
    const [uploadedFileFailedMessage, setUploadedFileFailedMessage] = useState('');
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [answersList, setAnswersList] = useState();
    const [loadingActive, setLoadingActive] = useState(false);
    const [companyLogoInvalidURL, setCompanyLogoInvalidURL] = useState(false);
    const [isTocAccepted, setIsTocAccepted] = useState(false);

    const recaptchaRef = React.createRef();

    const getAllQuestionsForJobAd = (jobid) => {
        API.get(RECRUITMENT_ADS_WITH_ID(jobid)).then(function (result) {
            if (result && result.questions && result.id) {
                setQuestionnaireId(result.id)
                setQuestionnaireList(result.questions)
            }
            else {
                setQuestionnaireId(0)
                setQuestionnaireList([])
            }
        });
    };

    useEffect(() => {
        // eslint-disable-next-line
        if (props.jobAdDetailsData && props.jobAdDetailsData.id){
            setJobAdDetails(props.jobAdDetailsData)
            if(props.jobAdDetailsData._type == "RecruitmentAd")
                getAllQuestionsForJobAd(props.jobAdDetailsData.id);
        }
    }, [props.jobAdDetailsData]);

    const checkIfAnswerExistInAnswerList = (answerID) => {
        let answerExist = false;
        if (answersList && answersList.length) {
            answersList.forEach(function (answer) {
                if (answer.questionId == answerID)
                    answerExist = true
            })
        }
        return answerExist;
    }

    const updateAndGetAnswerList = (answerData) => {
        let updatedAnswerList = [...answersList];

        updatedAnswerList.forEach(function (answer) {
            if (answer.questionId == answerData.questionId) {
                answer.value = answerData.value
            }
        })

        return updatedAnswerList;
    }

    const getSelectedMultiChoiceOptionsForQuestionID = (questionID) => {
        let selectedOptions = ''
        if (answersList)
            answersList.forEach(function (answer) {
                if (answer.questionId == questionID) {
                    selectedOptions = answer.value
                }
            })

        return selectedOptions
    }

    const handleQuestionnaireChange = (questionItem, answerItem) => {
        let answerData = {
            questionId: questionItem.id,
            value: 0
        }

        switch (questionItem.type) {
            case 'input':
                answerData.value = answerItem
                break;
            case 'choice':
                answerData.value = answerItem.index;
                break;
            case 'multichoice':
                let multiChoiceExistingData = getSelectedMultiChoiceOptionsForQuestionID(questionItem.id)
                if (multiChoiceExistingData || multiChoiceExistingData === 0) {
                    if (multiChoiceExistingData.includes(answerItem.index)) {
                        multiChoiceExistingData = multiChoiceExistingData.filter(item => item !== answerItem.index)
                    }
                    else {
                        multiChoiceExistingData.push(answerItem.index);
                    }
                    answerData.value = multiChoiceExistingData
                }
                else
                    answerData.value = [answerItem.index];

                break;
        }


        let _answersList = [];
        if (checkIfAnswerExistInAnswerList(questionItem.id)) {
            _answersList = updateAndGetAnswerList(answerData)
        }
        else {
            if (answersList)
                _answersList = [...answersList];
            _answersList.push(answerData)
        }

        setAnswersList(_answersList)
    }

    const resetNotificationMsg = () => {
        setNotificationMsg({ type: '', message: '' })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isFormValid = () => {
        let isValid = true;

        if (firstName === "") {
            isValid = false;
            setFirstNameValidationFailed(true);
        } else setFirstNameValidationFailed(false);

        if (lastName === "") {
            isValid = false;
            setLastNameValidationFailed(true);
        } else setFirstNameValidationFailed(false);

        if (email === "") {
            let emailErrMsg = props.globalConfig?.translations?.EmailIsRequired ? props.globalConfig?.translations?.EmailIsRequired : 'Email is required'
            setEmailErrorMsg(emailErrMsg);
            setEmailValidationFailed(true);
            isValid = false;
        } else if (!validateEmail(email)) {
            let emailErrMsg = props.globalConfig?.translations?.EnterValidEmailAddress ? props.globalConfig?.translations?.EnterValidEmailAddress : 'Enter valid email address'
            setEmailErrorMsg(emailErrMsg);
            setEmailValidationFailed(true);
            isValid = false;
        } else {
            setEmailErrorMsg("");
            setEmailValidationFailed(false);
        }

        if (getRequiredParamByFieldName('mobilePhone') && mobilePhone === "") {
            setMobilePhoneValidationFailed(true);
            isValid = false;
        } else {
            // eslint-disable-next-line
            var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            let phoneValidationFailed = phoneRegex.test(mobilePhone);
            setMobilePhoneValidationFailed(phoneValidationFailed ? false : true);
        }

        if (getRequiredParamByFieldName('homePhone') && homePhone === "") {
            setHomePhoneValidationFailed(true);
            isValid = false;
        } else {
            // eslint-disable-next-line
            var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            let phoneValidationFailed = phoneRegex.test(homePhone);
            setHomePhoneValidationFailed(phoneValidationFailed ? false : true);
        }

        if (getRequiredParamByFieldName('birthDate')) {
            let todayDate = new Date()
            let valueForValidation = new Date(birthDate)
            todayDate.setFullYear(todayDate.getFullYear() - 14);
            if ((birthDate === "" || !birthDate)) {
                setBirthDateValidationFailed(true)
                setBirthDateErrorMsg(props.globalConfig?.translations?.BirthDateIsRequired ? props.globalConfig?.translations?.BirthDateIsRequired : 'Birth date is required')
            }
            else if (valueForValidation === "" || !valueForValidation || todayDate < valueForValidation) {
                setBirthDateValidationFailed(true);
                setBirthDateErrorMsg(props.globalConfig?.translations?.DateOfBirthIsNotValid ? props.globalConfig?.translations?.DateOfBirthIsNotValid : 'Birth date is not valid')
            }
            else {
                setBirthDateValidationFailed(false);
                setBirthDateErrorMsg('')
            }

        }

        if (getRequiredParamByFieldName('address') && address === "") {
            isValid = false;
            setAddressValidationFailed(true);
        } else setAddressValidationFailed(false);

        if (getRequiredParamByFieldName('postalCode') && postalCode === "") {
            isValid = false;
            setPostalCodeValidationFailed(true);
        } else setPostalCodeValidationFailed(false);

        if (getRequiredParamByFieldName('city') && city === "") {
            isValid = false;
            setCityValidationFailed(true);
        } else setCityValidationFailed(false);

        if (getRequiredParamByFieldName('personalLetter') && personalLetter === "") {
            isValid = false;
            setPersonalLetterValidationFailed(true);
        } else setPersonalLetterValidationFailed(false);

        if (getRequiredParamByFieldName('CV_file') && (uploadedFileName === "" || uploadedFileBase64 === "")) {
            isValid = false;
            setUploadedFileFailed(true);
            setUploadedFileFailedMessage(props.globalConfig?.translations?.CVFileIsRequired ? props.globalConfig?.translations?.CVFileIsRequired : 'CV file is required')
        } 
        else if(uploadedFileFailedMessage){
            isValid = false;
        }
        else setUploadedFileFailed(false);

        return isValid;
    };
    const removeUploadedFile = () => {
        setUploadedFileName('')
        setUploadedFileBase64('')
        if(getRequiredParamByFieldName('CV_file')){
            setUploadedFileFailed(true)
            setUploadedFileFailedMessage(props.globalConfig?.translations?.CVFileIsRequired ? props.globalConfig?.translations?.CVFileIsRequired : 'CV file is required')    
        }
        else{
            setUploadedFileFailed(false)
            setUploadedFileFailedMessage('')
        }
    }
    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    async function handleFileUpload(event) {
        if (event.target.files && event.target.files.length && event.target.files[0] && event.target.files[0].name) {
            if(event.target.files[0].size < uploadedFileSizeLimit){
                setUploadedFileName(event.target.files[0].name)

                let fileForUpload = event.target.files[0];
                if (fileForUpload) {
                    const base64 = await convertBase64(fileForUpload)
                    if(!base64 || base64=='data:'){
                        setUploadedFileBase64('')
                        setUploadedFileFailed(true)
                        setUploadedFileFailedMessage(props.globalConfig?.translations?.CVFileCanNotBeEmpty ? props.globalConfig?.translations?.CVFileCanNotBeEmpty : 'CV file can not be empty')
                    }
                    else{
                        setUploadedFileBase64(base64)
                        setUploadedFileFailed(false)
                        setUploadedFileFailedMessage('')    
                    }

                }
            }
            else{
                setUploadedFileFailed(true)
                setUploadedFileFailedMessage(props.globalConfig?.translations?.CVFileIsTooLarge ? props.globalConfig?.translations?.CVFileIsTooLarge : 'CV file is too large')

            }
        }
        else {
            setUploadedFileFailed(true)
            setUploadedFileFailedMessage(props.globalConfig?.translations?.CVFileIsRequired ? props.globalConfig?.translations?.CVFileIsRequired : 'CV file is required')

        }
    }

    const resetCaptcha = () => {
        if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset)
            recaptchaRef.current.reset();
    }

    const onSubmitWithReCAPTCHA = () => {
        const token = recaptchaRef.current.execute();
        if (token && props.jobAdDetailsData && props.jobAdDetailsData.id) {
            setLoadingActive(true)
            let answersListPreparedForAPI = []
            if (answersList && answersList.length) {
                answersListPreparedForAPI = [...answersList]
                answersListPreparedForAPI.forEach(function (answer) {
                    if (answer && answer.value && answer.value.join)
                        answer.value = answer.value.join(",")
                    else if (answer && answer.value && answer.value.toString)
                        answer.value = answer.value.toString()
                })
            }
            let attachmentData = {};
            if (uploadedFileBase64 && uploadedFileName) {
                attachmentData.content = uploadedFileBase64;
                attachmentData.fileName = uploadedFileName;
            }

            let jobAdsPostData = {
                resource: {
                    firstName: firstName,
                    lastName: lastName,
                    primaryEmail: email,
                    birthDate: birthDateFormated,
                    phone1: mobilePhone,
                    phone2: homePhone,
                    address: {
                        street: address,
                        city: city,
                        postalCode: postalCode,
                    },
                },
                about: personalLetter,
                questionnaireId: questionnaireId,
                answers: answersListPreparedForAPI,
                attachment: attachmentData
            }

            let jobApplicationEndpoint = RECRUITMENT_ADS_WITH_ID_APPS(props.jobAdDetailsData.id)
            if(props.jobAdDetailsData._type != "RecruitmentAd"){
                jobAdsPostData = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: mobilePhone
                }
                jobApplicationEndpoint = INQUIRY_ADS_WITH_ID_APPS(props.jobAdDetailsData.id)
            }

            if(props.globalConfig?.companyTermsAndConditionsURL){
                jobAdsPostData.isTocAccepted = isTocAccepted
            }
            
            API.post(jobApplicationEndpoint, jobAdsPostData).then(function (result) {
                if (result && result.errors && result.errors.length) {
                    let messageText = '';
                    result.errors.forEach(function (message) {
                        if(message == 'Wrong Base64 format!')
                            message = 'CV file missing or invalid format is used. Only .pdf files are accepted.'
                        messageText += "-" + message + "\n"
                    })
                    resetCaptcha()
                    setNotificationMsg({
                        type: 'error',
                        message: messageText
                    })
                    setTimeout(resetNotificationMsg, 5000)
                    setLoadingActive(false)
                }
                else {
                    if(props.jobAdDetailsData._type != "RecruitmentAd"){
                        result = props.globalConfig?.translations?.ApplicationSaved ? props.globalConfig?.translations?.ApplicationSaved : 'ApplicationSaved'
                    }
                    setNotificationMsg({
                        type: 'success',
                        message: result
                    })
                    setTimeout(function () {
                        setLoadingActive(false)
                        resetNotificationMsg()
                        props.handleUserNavigation(APP_VIEWS.JOB_ADS_LIST)
                    }, 3000)
                }
            });

            resetCaptcha()
        }
        else {
            setLoadingActive(false)
            resetCaptcha()
            setNotificationMsg({
                type: 'error',
                message: 'Formulärdata är inte giltiga'
            })
            setTimeout(resetNotificationMsg, 5000)
        }
    }

    const onReCAPTCHAChange = (value) => {
        if (value == '') {
            setNotificationMsg(false)
            setNotificationMsg({
                type: 'success',
                message: 'Ansökan har skickats'
            })
        }
    }

    const submitJobApplicationHandler = () => {
        if (isFormValid()) {
            onSubmitWithReCAPTCHA()
        }
        else {
            resetCaptcha()
            setLoadingActive(false)
            setNotificationMsg({
                type: 'error',
                message: 'Formulärdata är inte giltiga'
            })
            setTimeout(resetNotificationMsg, 5000)
        }
    };

    const isHiddenFieldByFieldName = (fieldName) => {
        let isHidden = true;
        let hiddenFields = props.globalConfig?.jobApplicationFormSettings?.hiddenFields;
        if (hiddenFields && hiddenFields.length) {
            isHidden = hiddenFields.includes(fieldName);
        }
        else {
            isHidden = false
        }
        return isHidden;
    }

    const getRequiredParamByFieldName = (fieldName) => {
        let isRequired = false;
        let requiredFieldList = props.globalConfig?.jobApplicationFormSettings?.requiredFields;
        if (requiredFieldList && requiredFieldList.length) {
            isRequired = requiredFieldList.includes(fieldName);
        }
        return isRequired;
    }

    const isSubmitBtnDisabled = () => {
        let isButtonDisabled = false;
        if (firstName === "" || lastName === "" || email === "" || !validateEmail(email) || mobilePhone === "" || loadingActive)
            isButtonDisabled = true
        if (getRequiredParamByFieldName('birthDate') && birthDate === "")
            isButtonDisabled = true
        if (getRequiredParamByFieldName('homePhone') && homePhone === "")
            isButtonDisabled = true
        if (getRequiredParamByFieldName('address') && address === "")
            isButtonDisabled = true
        if (getRequiredParamByFieldName('postalCode') && postalCode === "")
            isButtonDisabled = true
        if (getRequiredParamByFieldName('city') && city === "")
            isButtonDisabled = true
        if (getRequiredParamByFieldName('CV_file') && (uploadedFileName === "" || uploadedFileBase64 === ""))
            isButtonDisabled = true
        if (getRequiredParamByFieldName('personalLetter') && personalLetter === "")
            isButtonDisabled = true
        if(uploadedFileFailedMessage)
            isButtonDisabled = true

        if(!isTocAccepted)
            isButtonDisabled = true
        
        return isButtonDisabled;
    };

    const handleBirthDateChange = (event) => {
        let todayDate = new Date()
        let valueForValidation = new Date(event.target.value)
        todayDate.setFullYear(todayDate.getFullYear() - 14);
        if (getRequiredParamByFieldName('birthDate')) {
            if (valueForValidation === "" || !valueForValidation) {
                setBirthDateValidationFailed(true)
                setBirthDateErrorMsg(props.globalConfig?.translations?.DateOfBirthIsRequired ? props.globalConfig?.translations?.DateOfBirthIsRequired : 'Birth date is required')
            }
            else if (todayDate < valueForValidation) {
                setBirthDateValidationFailed(true)
                setBirthDateErrorMsg(props.globalConfig?.translations?.DateOfBirthIsNotValid ? props.globalConfig?.translations?.DateOfBirthIsNotValid : 'Birth date is not valid')
            }
            else {
                setBirthDateValidationFailed(false)
                setBirthDateErrorMsg('')
            }
        }
    }

    return (
        <Box component="form" className="ApplyJobFormWrapper">
            {notificationMsg && notificationMsg.type &&
                <Alert severity={notificationMsg.type} className="alertWrapper">
                    <AlertTitle className="alertTitle">{notificationMsg.type}</AlertTitle>
                    {notificationMsg.message}
                </Alert>
            }
            <div className="container">
                {loadingActive &&
                    <div className="loadingFormContainer">
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                }
                <div className="jobApplicationForm">
                    <div className="fullRow navigateBackBtnWrapper">
                        <Button
                            size="medium"
                            className="floatLeft navBackBtn"
                            onClick={() => {
                                props.setShowApplyToJobForm(false);
                            }}
                            style={(props.customConfigStyling && props.customConfigStyling.getSecondaryButtonStyling) ? props.customConfigStyling.getSecondaryButtonStyling() : {}}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-arrow-left-short"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                />
                            </svg>
                            {props.globalConfig?.translations?.Back ? props.globalConfig?.translations?.Back : 'Back'}
                        </Button>
                    </div>
                    <div className="headerWrapper">
                        {props.globalConfig?.companyLogoURL && !companyLogoInvalidURL &&
                            <div className="logoWrapper row">
                                <img className="logo" src={props.globalConfig?.companyLogoURL} onError={()=>{setCompanyLogoInvalidURL(true)}}/>
                            </div>
                        }
                        <div className="titleWrapper row">
                            <h3>{(jobAdDetails && jobAdDetails.title) ? jobAdDetails.title : ''}</h3>
                        </div>
                    </div>
                    <div className="fullrow descriptionRow">
                        <div className={"descriptionWrapper"} dangerouslySetInnerHTML={{__html: ((jobAdDetails && jobAdDetails.description) ? jobAdDetails.description : '')}}></div>
                    </div>

                    <div className="formWrapper">
                        <div className="fieldWrapper">
                            <TextField
                                required={true}
                                label={props.globalConfig?.translations?.FirstName ? props.globalConfig?.translations?.FirstName : 'First name'}
                                className="inputField"
                                value={firstName}
                                inputProps={{
                                    tabIndex: 1,
                                    required: true,
                                }}
                                InputProps={{
                                    className: "stdInputFiled",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={
                                    (firstNameValidationFailed) ? (props.globalConfig?.translations?.FirstNameIsRequired ? props.globalConfig?.translations?.FirstNameIsRequired : 'First name is required') : undefined
                                }
                                onChange={(event) => {
                                    setFirstName(event.target.value);
                                    setFirstNameValidationFailed(event.target.value === "" ? true : false);
                                }}
                                onBlur={(event) => {
                                    let newValue = event.target.value;
                                    if (newValue && newValue.length) {
                                        newValue = newValue.trimStart().trimEnd();
                                    }

                                    setFirstName(newValue);
                                    setFirstNameValidationFailed(newValue === "" ? true : false);
                                }}
                                error={firstNameValidationFailed}
                            />
                        </div>
                        <div className="fieldWrapper">
                            <TextField
                                required={true}
                                label={props.globalConfig?.translations?.LastName ? props.globalConfig?.translations?.LastName : 'Last name'}
                                className="inputField"
                                value={lastName}
                                inputProps={{
                                    tabIndex: 2,
                                    required: true,
                                }}
                                InputProps={{
                                    className: "stdInputFiled",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={
                                    (lastNameValidationFailed) ? (props.globalConfig?.translations?.LastNameIsRequired ? props.globalConfig?.translations?.LastNameIsRequired : 'Last name is required') : undefined
                                }
                                onChange={(event) => {
                                    setLastName(event.target.value);
                                    setLastNameValidationFailed(event.target.value === "" ? true : false);

                                }}
                                onBlur={(event) => {
                                    let newValue = event.target.value;
                                    if (newValue && newValue.length) {
                                        newValue = newValue.trimStart().trimEnd();
                                    }

                                    setLastName(newValue);
                                    setLastNameValidationFailed(newValue === "" ? true : false);
                                }}
                                error={lastNameValidationFailed}
                            />
                        </div>



                        <div className="fieldWrapper">
                            <TextField
                                required={true}
                                label={props.globalConfig?.translations?.Email ? props.globalConfig?.translations?.Email : 'Email'}
                                className="inputField"
                                value={email}
                                inputProps={{
                                    tabIndex: 3,
                                    required: true,
                                    type: "email"
                                }}
                                InputProps={{
                                    className: "stdInputFiled",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={emailErrorMsg}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    if (event.target.value === "") {
                                        let emailErrMsg = props.globalConfig?.translations?.EmailIsRequired ? props.globalConfig?.translations?.EmailIsRequired : 'Email is required'
                                        setEmailErrorMsg(emailErrMsg);
                                        setEmailValidationFailed(true);
                                    } else {
                                        setEmailErrorMsg("");
                                        setEmailValidationFailed(false);
                                    }
                                }}
                                onBlur={(event) => {
                                    let newValue = event.target.value;
                                    if (newValue && newValue.length) {
                                        newValue = newValue.trimStart().trimEnd();
                                    }

                                    setEmail(newValue);
                                    if (newValue === "") {
                                        let emailErrMsg = props.globalConfig?.translations?.EmailIsRequired ? props.globalConfig?.translations?.EmailIsRequired : 'Email is required'

                                        setEmailErrorMsg(emailErrMsg);
                                        setEmailValidationFailed(true);
                                    } else if (!validateEmail(newValue)) {
                                        let emailErrMsg = props.globalConfig?.translations?.EnterValidEmailAddress ? props.globalConfig?.translations?.EnterValidEmailAddress : 'Enter valid email address'
                                        setEmailErrorMsg(emailErrMsg);
                                        setEmailValidationFailed(true);
                                    } else {
                                        setEmailErrorMsg("");
                                        setEmailValidationFailed(false);
                                    }
                                }}
                                error={emailValidationFailed}
                            />
                        </div>
                        <div className="fieldWrapper">
                            <Tooltip title="Country telephone code is required" placement="bottom" arrow>
                                <TextField
                                    required={true}
                                    label={props.globalConfig?.translations?.Phone ? props.globalConfig?.translations?.Phone : 'Phone'}
                                    className="inputField"
                                    value={mobilePhone}
                                    inputProps={{
                                        tabIndex: 4,
                                        required: true,
                                        type: "tel", 
                                        placeholder: "+46 xx xxx xxxx"
                                    }}
                                    InputProps={{
                                        className: "stdInputFiled",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIphoneOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        (mobilePhoneValidationFailed) ? (props.globalConfig?.translations?.PhoneIsRequired ? props.globalConfig?.translations?.PhoneIsRequired : 'Phone is required') : undefined
                                    }
                                    onChange={(event) => {
                                        setMobilePhone(event.target.value);
                                        // eslint-disable-next-line
                                        var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
                                        let phoneValidationFailed = phoneRegex.test(event.target.value);
                                        setMobilePhoneValidationFailed(phoneValidationFailed ? false : true);
                                    }}
                                    onBlur={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue && newValue.length) {
                                            newValue = newValue.trimStart().trimEnd();
                                        }

                                        setMobilePhone(newValue);
                                        // eslint-disable-next-line
                                        var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
                                        let phoneValidationFailed = phoneRegex.test(event.target.value);
                                        setMobilePhoneValidationFailed(phoneValidationFailed ? false : true);
                                    }}
                                    error={mobilePhoneValidationFailed}
                                />
                            </Tooltip>
                        </div>

                        {(getRequiredParamByFieldName('homePhone') || !isHiddenFieldByFieldName('homePhone')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                            <div className="fieldWrapper">
                                <Tooltip title="Country telephone code is required" placement="bottom" arrow>
                                    <TextField
                                        required={getRequiredParamByFieldName('homePhone')}
                                        label={props.globalConfig?.translations?.HomePhone ? props.globalConfig?.translations?.HomePhone : 'Home phone'}
                                        className="inputField"
                                        value={homePhone}
                                        inputProps={{
                                            tabIndex: 5,
                                            required: getRequiredParamByFieldName('homePhone'),
                                            type: "tel",
                                            placeholder: "+46 xx xxx xxxx"
                                        }}
                                        InputProps={{
                                            className: "stdInputFiled",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIphoneOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            (getRequiredParamByFieldName('homePhone') && homePhoneValidationFailed) ? (props.globalConfig?.translations?.PhoneIsRequired ? props.globalConfig?.translations?.PhoneIsRequired : 'Phone is required') : undefined
                                        }
                                        onChange={(event) => {
                                            setHomePhone(event.target.value);
                                            // eslint-disable-next-line
                                            var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
                                            let phoneValidationFailed = phoneRegex.test(event.target.value);
                                            setHomePhoneValidationFailed(phoneValidationFailed ? false : true);
                                        }}
                                        onBlur={(event) => {
                                            let newValue = event.target.value;
                                            if (newValue && newValue.length) {
                                                newValue = newValue.trimStart().trimEnd();
                                            }

                                            setHomePhone(newValue);
                                            // eslint-disable-next-line
                                            var phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
                                            let phoneValidationFailed = phoneRegex.test(event.target.value);
                                            setHomePhoneValidationFailed(phoneValidationFailed ? false : true);
                                        }}
                                        error={(getRequiredParamByFieldName('homePhone') && homePhoneValidationFailed)}
                                    />
                                </Tooltip>
                            </div>
                        }
                        {(getRequiredParamByFieldName('birthDate') || !isHiddenFieldByFieldName('birthDate')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                            <div className="fieldWrapper">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        disableFuture
                                        className="inputField datePicker"
                                        label={props.globalConfig?.translations?.DateOfBirth ? props.globalConfig?.translations?.DateOfBirth : 'Date of birth'}
                                        openTo="year"
                                        views={['year', 'month', 'day']}
                                        value={birthDate}
                                        InputAdornmentProps={{ position: 'start' }}
                                        InputProps={{
                                            className: "stdInputFiled",
                                        }}
                                        inputFormat={"YYYY-MM-DD"}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                required={getRequiredParamByFieldName('birthDate')}
                                                tabIndex={6}
                                                onBlur={handleBirthDateChange}
                                                helperText={
                                                    (getRequiredParamByFieldName('birthDate') && birthDateValidationFailed) ? birthDateErrorMsg : undefined
                                                }
                                                error={(getRequiredParamByFieldName('birthDate') && birthDateValidationFailed)}
                                            />
                                        }

                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                            if (newValue)
                                                setBirthDateFormated(newValue.format("YYYY-MM-DD"));
                                            else
                                                setBirthDateFormated('');

                                            if (getRequiredParamByFieldName('birthDate')) {
                                                setBirthDateValidationFailed((newValue === "" || !newValue) ? true : false);
                                            }
                                        }}
                                        required={getRequiredParamByFieldName('birthDate')}
                                    />
                                </LocalizationProvider>
                            </div>
                        }


                        {(getRequiredParamByFieldName('address') || !isHiddenFieldByFieldName('address')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                            <div className="fieldWrapper">
                                <TextField
                                    required={getRequiredParamByFieldName('address')}
                                    label={props.globalConfig?.translations?.Address ? props.globalConfig?.translations?.Address : 'Address'}
                                    className="inputField"
                                    value={address}
                                    inputProps={{
                                        tabIndex: 7,
                                        required: getRequiredParamByFieldName('address')
                                    }}
                                    InputProps={{
                                        className: "stdInputFiled",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <HomeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        (getRequiredParamByFieldName('address') && addressValidationFailed) ? (props.globalConfig?.translations?.AddressIsRequired ? props.globalConfig?.translations?.AddressIsRequired : 'Address is required') : undefined
                                    }
                                    onChange={(event) => {
                                        setAddress(event.target.value);

                                        if (getRequiredParamByFieldName('address')) {
                                            setAddressValidationFailed(
                                                event.target.value === "" ? true : false
                                            );
                                        }

                                    }}
                                    onBlur={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue && newValue.length) {
                                            newValue = newValue.trimStart().trimEnd();
                                        }

                                        setAddress(newValue);

                                        if (getRequiredParamByFieldName('address')) {
                                            setAddressValidationFailed(newValue === "" ? true : false);
                                        }
                                    }}
                                    error={(addressValidationFailed && getRequiredParamByFieldName('address'))}
                                />
                            </div>
                        }

                        {(getRequiredParamByFieldName('postalCode') || !isHiddenFieldByFieldName('postalCode')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                            <div className="fieldWrapper">
                                <TextField
                                    required={getRequiredParamByFieldName('postalCode')}
                                    label={props.globalConfig?.translations?.PostalCode ? props.globalConfig?.translations?.PostalCode : 'Postal code'}
                                    className="inputField middleElement"
                                    value={postalCode}
                                    inputProps={{
                                        tabIndex: 8,
                                        required: getRequiredParamByFieldName('postalCode')
                                    }}
                                    InputProps={{
                                        className: "stdInputFiled",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        (getRequiredParamByFieldName('postalCode') && postalCodeValidationFailed) ? (props.globalConfig?.translations?.PostalCodeIsRequired ? props.globalConfig?.translations?.PostalCodeIsRequired : 'Postal code is required') : undefined
                                    }
                                    onChange={(event) => {
                                        setPostalCode(event.target.value);

                                        if (getRequiredParamByFieldName('postalCode')) {
                                            setPostalCodeValidationFailed(
                                                event.target.value === "" ? true : false
                                            );
                                        }

                                    }}
                                    onBlur={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue && newValue.length) {
                                            newValue = newValue.trimStart().trimEnd();
                                        }

                                        setPostalCode(newValue);

                                        if (getRequiredParamByFieldName('postalCode')) {
                                            setPostalCodeValidationFailed(newValue === "" ? true : false);
                                        }
                                    }}
                                    error={(postalCodeValidationFailed && getRequiredParamByFieldName('address'))}
                                />
                            </div>
                        }
                        {(getRequiredParamByFieldName('city') || !isHiddenFieldByFieldName('city')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                            <div className="fieldWrapper">
                                <TextField
                                    required={getRequiredParamByFieldName('city')}
                                    label={props.globalConfig?.translations?.City ? props.globalConfig?.translations?.City : 'City'}
                                    className="inputField"
                                    value={city}
                                    inputProps={{
                                        tabIndex: 9,
                                        required: getRequiredParamByFieldName('city')
                                    }}
                                    InputProps={{
                                        className: "stdInputFiled",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationCityIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    helperText={
                                        (getRequiredParamByFieldName('city') && cityValidationFailed) ? (props.globalConfig?.translations?.CityIsRequired ? props.globalConfig?.translations?.CityIsRequired : 'City is required') : undefined
                                    }
                                    onChange={(event) => {
                                        setCity(event.target.value);

                                        if (getRequiredParamByFieldName('postalCode')) {
                                            setPostalCodeValidationFailed(
                                                event.target.value === "" ? true : false
                                            );
                                        }

                                    }}
                                    onBlur={(event) => {
                                        let newValue = event.target.value;
                                        if (newValue && newValue.length) {
                                            newValue = newValue.trimStart().trimEnd();
                                        }

                                        setCity(newValue);

                                        if (getRequiredParamByFieldName('city')) {
                                            setCityValidationFailed(newValue === "" ? true : false);
                                        }
                                    }}
                                    error={(cityValidationFailed && getRequiredParamByFieldName('city'))}
                                />
                            </div>
                        }
                    </div>
                    {(getRequiredParamByFieldName('CV_file') || !isHiddenFieldByFieldName('CV_file')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                        <div className="fullrow inputWrapper cvFileRow mobile-mb-25">
                            <Button variant="contained" component="label" className="fileUploadButton floatLeft" tabIndex={11}>
                                {props.globalConfig?.translations?.UploadCV ? props.globalConfig?.translations?.UploadCV : 'Upload CV'} {getRequiredParamByFieldName('CV_file') ? "*" : ""}
                                <input hidden type="file" onChange={handleFileUpload} accept="application/pdf"/>
                            </Button>

                            {uploadedFileName &&
                                <div className="uploadedFileNameWrapper floatLeft">
                                    <span className="uploadedFileName">{uploadedFileName}</span>
                                    <IconButton aria-label="delete" size="small" onClick={removeUploadedFile} className="removeFileBtn">
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            }
                            {uploadedFileFailed &&
                                <span className="cvErrorMessage">*{uploadedFileFailedMessage}</span>
                            }
                            <div className="fullrow cvInfoText">
                                <span>{props.globalConfig?.translations?.AllowedFileFormats ? props.globalConfig?.translations?.AllowedFileFormats : 'Allowed file formats: '}.pdf</span>
                                <span>{props.globalConfig?.translations?.MaxFileSizeIs ? props.globalConfig?.translations?.MaxFileSizeIs : 'Max file size is'} 10MB</span>
                            </div>
                        </div>
                    }
                    {(getRequiredParamByFieldName('personalLetter') || !isHiddenFieldByFieldName('personalLetter')) && props.jobAdDetailsData._type == "RecruitmentAd" &&
                        <div className="fullrow inputWrapper mobile-mb-25">
                            <TextField
                                required={getRequiredParamByFieldName('personalLetter')}
                                className="stdInputFiled textAreaField"
                                label="Personligt brev"
                                multiline
                                rows={6}
                                value={personalLetter}
                                inputProps={{
                                    tabIndex: 12,
                                }}
                                helperText={
                                    (getRequiredParamByFieldName('personalLetter') && personalLetterValidationFailed) ? (props.globalConfig?.translations?.PersonalLetterIsRequired ? props.globalConfig?.translations?.PersonalLetterIsRequired : 'Personal letter is required') : undefined
                                }
                                onChange={(event) => {
                                    setPersonalLetter(event.target.value);

                                    if (getRequiredParamByFieldName('personalLetter')) {
                                        setPersonalLetterValidationFailed(
                                            event.target.value === "" ? true : false
                                        );
                                    }

                                }}
                                onBlur={(event) => {
                                    let newValue = event.target.value;
                                    if (newValue && newValue.length) {
                                        newValue = newValue.trimStart().trimEnd();
                                    }

                                    setPersonalLetter(newValue);

                                    if (getRequiredParamByFieldName('personalLetter')) {
                                        setPersonalLetterValidationFailed(newValue === "" ? true : false);
                                    }
                                }}
                                error={(personalLetterValidationFailed && getRequiredParamByFieldName('personalLetter'))}
                            />
                        </div>
                    }

                    {questionnaireList &&
                        <Questionnaire
                            questionnaireList={questionnaireList}
                            handleQuestionnaireChange={handleQuestionnaireChange}
                            startTabIndex={12}
                        />
                    }

                    <div className="fullrow inputWrapper mobile-mb-25">
                        <ReCAPTCHA
                            sitekey={recaptchaSiteKey}
                            onChange={onReCAPTCHAChange}
                            ref={recaptchaRef}
                            size="invisible"
                            onExpired={resetCaptcha}
                            onErrored={resetCaptcha}
                        />
                    </div>

                    {props.globalConfig?.companyTermsAndConditionsURL && 
                        <div className="fullrow chBoxWrapper mt-3">
                            <label className="chBoxLabel">
                                <Checkbox required value={isTocAccepted} onChange={()=>{
                                    setIsTocAccepted(!isTocAccepted)
                                }}/> 
                                    <span className="chBoxLabelText">
                                        {props.globalConfig?.translations?.AgreeTo ? props.globalConfig.translations.AgreeTo : 'Agree to'} &nbsp; <a href={props.globalConfig.companyTermsAndConditionsURL} target="_blank">{props.globalConfig?.translations?.TermsAndConditions ? props.globalConfig.translations.TermsAndConditions : 'Terms and conditions'}</a>
                                    </span>
                            </label>
                        </div>
                    }
                    
                    <div className="fullrow submitWrapper">
                        <Button
                            size="medium"
                            className="navBackBtn"
                            onClick={() => {
                                props.setShowApplyToJobForm(false);
                            }}
                            style={(props.customConfigStyling && props.customConfigStyling.getSecondaryButtonStyling) ? props.customConfigStyling.getSecondaryButtonStyling() : {}}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-arrow-left-short"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                />
                            </svg>
                            {props.globalConfig?.translations?.Back ? props.globalConfig?.translations?.Back : 'Back'}
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            className="submitJobApplicationBtn"
                            style={(props.customConfigStyling && props.customConfigStyling.getPrimaryButtonStyling) ? props.customConfigStyling.getPrimaryButtonStyling() : {}}
                            onClick={submitJobApplicationHandler}
                            disabled={isSubmitBtnDisabled()}
                        >
                            {props.globalConfig?.translations?.Submit ? props.globalConfig?.translations?.Submit : 'Submit'}
                        </Button>
                    </div>
                    <div className="fullrow inputWrapper copyrightWrapper">
                        <span className="copyright"> &copy; {new Date().getFullYear()} LeanLink AB</span>
                    </div>
                </div>
            </div>
        </Box>
    );
}
